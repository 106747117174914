import { resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_maska = _resolveDirective("maska")!

  return _withDirectives((_openBlock(), _createElementBlock("input", {
    class: "form-control",
    value: _ctx.maskedValueM,
    onMaska: _cache[0] || (_cache[0] = ($event: any) => (_ctx.maskaFun($event)))
  }, null, 40, _hoisted_1)), [
    [_directive_maska, _ctx.mask]
  ])
}