
import { defineComponent, onMounted, ref, reactive, watch } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import useVuelidate from "@vuelidate/core";
import { email, helpers, required } from "@vuelidate/validators";
import { getPatient, addPatient, deletePatient, updatePatient } from "@/api/patient.api";
import { Patient } from "./patient.model";
import MaskRaw from "@/components/ABilling/MaskRaw.vue";

export interface Data {
  patient?: Patient;
  createNewMode: boolean;
  updateOnly: boolean;
  isReady: boolean;
}
export default defineComponent({
  name: "PatientDemographicsComponent",
  props: ["patient", "patientId", "updateOnly"],
  components: { MaskRaw },
  setup(props, ctx) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      patient: undefined,
      updateOnly: false,
      createNewMode: true,
      isReady: false,
    });

    data.patient = {} as Patient;

    watch(
      () => props.patient,
      () => {
        if (props.patient) {
          data.patient = props.patient as Patient;
          data.createNewMode = false;
        }
      }
    );

    onMounted(async () => {
      organizationId.value = getOrganization();
      if (props.patient) {
        data.patient = props.patient as Patient;
        data.createNewMode = false;
      } else if (props.patientId) {
        data.patient = await getPatient(props.patientId);
        data.createNewMode = false;
      }
      if (props.updateOnly) {
        data.updateOnly = props.updateOnly;
      }
      data.isReady = true;
    });

    async function switchMode() {
      data.createNewMode = true;
      data.patient = {} as Patient;
    }

    async function addItem() {
      const result = await v$.value.$validate();
      if (result) {
        await addPatient(data.patient);
        data.createNewMode = false;
        ctx.emit("itemChanged");
      }
    }

    async function updateItem() {
      const result = await v$.value.$validate();
      if (result) {
        await updatePatient(data.patient);
      }
    }

    async function deleteItem() {
      await deletePatient(data.patient);
      ctx.emit("itemChanged");
      ctx.emit("switchMode");
    }

    const isPhone = helpers.regex(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im // eslint-disable-line
    );
    const patientRules = {
      patient: {
        accountId: { required:helpers.withMessage("Required", required) },
        firstName: { required:helpers.withMessage("Required", required) },
        lastName: { required:helpers.withMessage("Required", required) },
        dob: { required:helpers.withMessage("Required", required) },
        sex: { required:helpers.withMessage("Required", required) },
        address: { required:helpers.withMessage("Required", required) },
        city: { required:helpers.withMessage("Required", required) },
        state: { required:helpers.withMessage("Required", required) },
        zip: { required:helpers.withMessage("Required", required) },
        email: { email },
        homePhone: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        officePhone: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
        mobilePhone: {
          isPhone: helpers.withMessage("Phone number is invalid", isPhone),
        },
      },
    };

    const v$ = useVuelidate(patientRules, data as never);

    return {
      organizationId,
      data,
      addItem,
      updateItem,
      deleteItem,
      switchMode,
      v$,
    };
  },
});
