
import { computed, defineComponent, PropType } from "vue";
import { maska, mask, tokens } from "maska";
import { number } from "yup/lib/locale";

export default defineComponent({
  props: ["modelValue", "mask", "type"],
  emits: ["update:modelValue"],
  setup(props, ctx) {
    const maskedValueM = computed(() =>
      mask(props.modelValue?.toString() ?? "", props.mask, tokens)
    );
    function maskaFun($event) {
      let val = $event.target.dataset.maskRawValue;
      if (val == "") val = null;
      if(props.type && props.type == "number" && val){
        val = parseInt(val);
      }
      ctx.emit("update:modelValue", val);
    }
    return { maskedValueM, maskaFun };
  },
});
