
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import ApiService from "@/core/services/ApiService";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { getPatient, updatePatient } from "@/api/patient.api";
import { updatePatientPayer } from "@/api/patientPayer.api";
import EncounterComponent from "@/modules/encounter/EncounterComponent.vue";
import PatientPayerComponent from "@/modules/patientPayer/PatientPayerComponent.vue";
import PatientDemographicsComponent from "@/modules/patient/PatientDemographicsComponent.vue";
import { Patient, PatientExtended } from "@/modules/patient/patient.model";
import { push } from "object-path";
import { Encounter } from "@/modules/encounter/encounter.model";
import { getPanel } from "@/api/encounter.api";

export interface Data {
  patientId;
  patient?: PatientExtended;
  panel: { facilities: []; patients: []; providers: [] };
  encountersList: EncounterInfo[];
}

export interface EncounterInfo {
  encounter?: Encounter;
  index: number;
}

export default defineComponent({
  name: "PatientPage",
  components: {
    EncounterComponent,
    PatientPayerComponent,
    PatientDemographicsComponent,
  },
  props: ["patientId"],
  setup(props) {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      patientId: props.patientId,
      patient: undefined,
      panel: { facilities: [], patients: [], providers: [] },
      encountersList: [],
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Patient Account");
      data.panel = await getPanel();
      await getPatientData();
    });

    async function itemChanged() {
      await getPatientData();
    }

    function addEncounter() {
      data.encountersList.push({
        encounter: undefined,
        index: Math.max(...data.encountersList.map((o) => o.index)) + 1,
      });
    }

    function undoEncounter(encounterInfo) {
      data.encountersList.splice(data.encountersList.indexOf(encounterInfo), 1);
    }

    async function getPatientData() {
      data.patient = await getPatient(data.patientId);
      data.encountersList = [];
      data.patient?.encounters.forEach((item, index) => {
        data.encountersList.push({ encounter: item, index: index });
      });
    }

    function getfacilityName(facilityId) {
      const facility = data.panel.facilities.find((facility: any) => {
        return facility.id == facilityId;
      }) as any;
      if (facility) return facility.name;
      return "";
    }

    return {
      organizationId,
      itemChanged,
      addEncounter,
      undoEncounter,
      getfacilityName,
      data,
    };
  },
});
